.form{
    display: grid;
    place-content: center;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    padding-bottom: 2rem;
}

.section{
    display: grid;
    place-content: center;
}

.section-rows{
    grid-row: span 4 / span 3;
}

.section-div{
    display: flex;
    justify-content: flex-start;
    margin: 2.5rem 0 .5rem .5rem;
}

.h1{
    display: grid;
    place-content: center;
    color: #874ed2;
    font-size: 2rem;
    line-height: 1.75rem;
}

.tips-margin{
    text-align: left;
    margin-left: 1rem;
    margin-right: 1rem;
}

.tips-h2{
    display: grid;
    place-content: start;
    color: #874ed2;
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-top: 1rem;
    margin-bottom: .5rem;
}

.h2{
    display: grid;
    place-content: center;
    color: #874ed2;
    font-size: 1.125rem;
    line-height: 1.75rem;
    width: 100%;
}

.photo{
    width: 113.5px;
    height: 151.2px;
}

.input-div{
    display: grid;
    width: 20rem;
}

.date-input-wrapper{
    display: flex;
    justify-content: space-between;
}

.date-input-div{
    display: grid;
    width: 9rem;
}

.input{
    background-color: rgba(196, 196, 196, 0.2);
    padding: .25rem 0 .25rem .5rem;
    border-radius: 0.125rem;
    margin-bottom: 1rem;
}

.input-lower{
    margin-top: .75rem;
}

.input::placeholder {
    opacity: 1;
}

.input:focus{
    outline-offset: 2px;
    outline-color:#fd705e;
}

.input[type="date"]{
    padding-left: 1.5rem;
}

.input[type="radio"]:checked{
    background-color: #874ed2;   
}

.page-div{
    margin: 1rem;
    background-color: white;
    height: 97%;
    padding-bottom: 1.25rem;
}

.print-page-div{
    margin: 1rem;
    background-color: white;
    width: 210mm;
}

.resume-section{
    margin-left: 5%;
    margin-right: 5%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.resume-bg-text{
    color: #874ed2;
    display: grid;
    place-content: center;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.resume-history-div{
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 2px;
    border-right-width: 2px;
}

.cert-history-div{
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-right-width: 2px;
}

.resume-history-header-div{
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    border-bottom-width: 2px;
    border-bottom-style: double;
    border-left-width: 2px;
    border-right-width: 2px;
    border-top-width: 2px;
}

.resume-history-year-div{
    border-right-width: 1px;
    display: grid;
    grid-column: span 2 / span 2;
    place-content: center;
    font-weight: 500;

}

.resume-history-month-div{
    border-right-width: 1px;
    display: grid;
    grid-column: span 1 / span 1;
    place-content: center;
    font-weight: 500;
}

.resume-history-title-div{
    display: grid;
    place-content: center;
    grid-column: span 9 / span 9;
    min-height: 30px;
    font-weight: 500;
}

.resume-history-text-div{
    display: grid;
    align-items: center;
    grid-column: span 9 / span 9;
    padding-left: .75rem;
    padding-right: .75rem;
    min-height: 30px;
}

.tipbox{
    z-index: 20;
    background-color: white;
    text-align: center;
    border-width: 2px;
    border-color: #874ed2;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 0.375rem;
    padding: .5rem;
}

/* Form3 css */
.text-area{
    background-color: rgba(196, 196, 196, 0.2);
    min-width: 17rem;
    height: 15rem;
    background: #f7f7f7;
    padding: 1rem;
}

.text-area:focus{
    outline-offset: 2px;
    outline-color:#fd705e;
}

.time-div{
    display: flex;
    gap: 0.5rem;
    color:#874ed2;
}

.drop-down{
    background: #fafafa;
    text-align: center;
    width: 5rem;
    height: 2rem;
    margin-right: .5rem;
    margin-top: .5rem;
}

.select-drop-down-div{
    display: flex;
    gap: 2.5rem;
    width: 100%;
}

.family-drop-down-div{
    color: #874ed2;
    margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
    .text-area {
        width: 32rem;
    }
    .drop-down {
        margin-top: 0;
    }
}

@media print {
    
    #body{
        background-color: #fff;
        width: 210mm;
        height: 297mm;
    }

    @page { 
       size: A4 portrait;
    }
    
    #resume-header, #button-group, #sub-header, #warning-text, #progress-bar{
        display: none !important;
    }

    #resume-print{
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        opacity: 100%;
        font-weight: 100;
        width: 210mm;
        height: 297mm;
        margin-left: 4rem;
    }

    .certifications{
        margin-top: 3rem;
    }

    .no-break{
        page-break-inside: avoid !important; 
        widows: 2;
        orphans: 2;
    }

    .break-before{
        display: block;
        page-break-before: always !important;
    }

}