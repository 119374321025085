.button-animation {
  background: linear-gradient(104.65deg, #874ed2 -2.69%, #fd705e 10.8%);
}

.button-animation:hover {
  background: linear-gradient(105.89deg, #874ED2 -3.72%, #FD705E 95.79%); 
}

.button{
  border-radius: 1rem;
  color: white;
  box-shadow: 0px 24px 20px 5px rgba(0, 0, 0, 0.15);
}

.circle-button{
  border-radius: 9999px;
  display: grid;
  place-content: center;
  color: white;
  box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.15);
  font-weight: 800;
  font-size: 1.25rem/* 20px */;
  line-height: 1.75rem/* 28px */;
  padding: 0.125rem;
  width: 2rem;
}

.button:hover{
  box-shadow: none;
}

.circle-button:hover{
  box-shadow: none;
}

.small-project-button{
  background: linear-gradient(104.65deg, #874ed2 -2.69%, #fd705e 10.8%);
  padding: 0.25rem;
  border-radius: 1rem;
  color: white;
  box-shadow: 0px 24px 20px 5px rgba(0, 0, 0, 0.15);
}

.small-project-button:hover {
  background: linear-gradient(105.89deg, #874ED2 -3.72%, #FD705E 95.79%);
  box-shadow: none; 
}