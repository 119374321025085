.background {
    background-image: url("../../images/mainpagebg.png");
}

.resume-bg1{
    background-image: url("../../images/resumepage1.png");
    min-height: 500px;
    background-size: contain;
    background-repeat: no-repeat;
    border: 2px black solid;

}

.skillsheet-bg{
    background-image: url("../../images/skillsheetpage.png");
    min-height: 400px;
    background-size: contain;
    border: 2px black solid;
}