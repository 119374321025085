#skillsheet-page-1{
    display: grid;
    place-content: center;
    margin: 0 .25rem 1rem .25rem;
    background-color: white;
    padding-bottom: 1.25rem;
    width: 210mm;
}

.table{
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    width: 100%;
    border-width: 2px 2px 0px 2px;
}

.text-area{
    background-color: rgba(196, 196, 196, 0.2);
    min-width: 16rem;
    max-width: 28rem;
    height: 15rem;
    background: #f7f7f7;
    padding: 1rem;
}

.form-text-area-box{
    white-space: pre-line;
}

.small-text-area{
    background-color: rgba(196, 196, 196, 0.2);
    width: 20rem;
    height: 7rem;
    background: #f7f7f7;
    padding: 1rem;
}

.text-area:focus{
    outline-offset: 2px;
    outline-color:#fd705e;
}

.select-input-div{
    display: flex;
    width: 100%;
}

.info-table-row{
    background-color: #f2f2f2;
    display: grid;
    grid-column: span 2 / span 2;
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    border-right-width: 2px;
}

.short-cell{
    display: grid;
    place-content: center;
    padding: 0.25rem/* 4px */;
    border-bottom-width: 2px;
    height: 2rem;
    word-break: break-all !important;
}

.no-bottom-short-cell{
    display: grid;
    place-content: center;
    padding: 0.25rem/* 4px */;
    height: 2rem;
    word-break: break-all !important;
}

.project-name-cell{
    display: grid;
    padding: 0.25rem;
    border-bottom-width: 2px;
    height: 2rem;
    word-break: break-all;
}

.medium-cell{
    display: grid;
    place-content: center;
    padding: 0.2rem;
    border-bottom-width: 2px;
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    word-break: break-all;
}

.no-bottom-medium-cell{
    display: grid;
    place-content: center;
    padding: 0.2rem;
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    word-break: break-all;
}

.languages-cell{
    display: inline-block;
    vertical-align: top;
    padding: 0.1rem;
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    word-break: break-all;
}

.overview-point-cell{
    display: grid;
    padding: 0.25rem;
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
    word-break: break-all;
}

.mid-cell{
    display: grid;
    grid-column: span 1 / span 1;
    border-width: 0px 2px 0px 2px;
}

.last-cell{
    display: grid;
    place-content: center;
    grid-column: span 1 / span 1;
}

.mini-table-container{
    display: grid;
    align-content: center;
    border-top-width: 2px;
    height: 2rem;
}

.mini-table{
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    width: 100%;
    height: 2rem;
}

.mini-table-row{
    display: grid;
    place-content: center;
    grid-column: span 1 / span 1;
    border-right-width: 2px;
}

.mini-table-td{
    text-align: center;
    font-size: 0.50rem/* 12px */;
    line-height: .7rem/* 16px */;
}

.tip-step-subtitle{
    text-align: center;
    margin-top: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #874ed2;
}

@media print {
    
    html, body{
        margin: 0 !important;
        padding: 0 !important;
        width: 210mm;
        height: 297mm;
        background-color: #fff;
    }
    
    @page { 
       size: A4;
    }
    
    #resume-header, #skillsheet-button-group, #sub-header, #warning-text, #progress-bar{
        display: none !important;
    }

    #skillsheet-page-1-bg{
        margin: 0 !important;
        padding: 0 !important;
        background-color: #fff !important;
    }

    #skillsheet-page-1{
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        display: block !important;
        width: 210mm;
        height: 297mm;
        padding: 0 !important;
        margin: 0 !important;
        clear: both;
        opacity: 100%;
        font-weight: 100;
    }
    
    .mini-table-td{
        text-align: center;
        font-size: 0.70rem/* 12px */;
        line-height: .8rem/* 16px */;
    }

    .project-tables{
        page-break-inside: avoid !important;
    }

}